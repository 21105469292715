<div *ngIf="flatResults && flatResults.length">

  <dx-toolbar class="yo-toolbar">
    <dxi-item location="before">
      <div class="d-flex flex-row align-items-center">
        <i class="fa-solid fa-layer-group mg-r-5 mg-l-15"></i> D&Eacute;TAILS
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div class="d-flex flex-row align-items-center">
        <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [width]="800"></yo-help>
      </div>
    </dxi-item>
  </dx-toolbar>

  <dx-data-grid
    id="tab-calcul-conditionnement"
    [dataSource]="flatResults"
    keyExpr="id"
    width="100%"
    [hoverStateEnabled]="true"
    [allowColumnResizing]="true"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
    [showBorders]="true"
    [allowColumnReordering]="true"
    columnResizingMode="widget"
    #grid>

    <dxi-column dataField="atelierLabel" caption="Atelier" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="equipeLabel" caption="Equipe" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="pointLivraisonLabel" caption="Point livraison client" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true" cellTemplate="plcTemplate">
    </dxi-column>
    <dxi-column dataField="repasLabel" caption="Repas" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="prestationLabel" caption="Prestation" [width]="100"
                [filterOperations]="['contains']" cellTemplate="prestaTemplate"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="declinaisonLabel" caption="Déclinaison" [width]="100"
                [filterOperations]="['contains']" cellTemplate="decliTemplate"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="regimeLabel" caption="Régime" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true"
                [groupIndex]="3">
    </dxi-column>
    <dxi-column dataField="poidsNetUc" caption="Poids net UC" [width]="100"
                [filterOperations]="['contains']" cellTemplate="poidsNetUcTemplate"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="qteConditionnee" caption="Quantité conditionnée" [width]="100"
                [filterOperations]="['contains']" cellTemplate="qteConditionneeTemplate"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="nbPiecesCalculated" caption="Nombre pièce(s)" cellTemplate="nbPiecesTemplate" [width]="100" [filterOperations]="['contains']" [allowGrouping]="true"></dxi-column>
    <dxi-column dataField="conditionnementLabel" caption="Conditionnement" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="varianteLabel" caption="Variante" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="queueUc" caption="Reste" [width]="100"
                [filterOperations]="['contains']"
                cellTemplate="resteTemplate"
                [allowGrouping]="true">
    </dxi-column>

    <dxi-column dataField="dateConditionnement" caption="Date conditionnement" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="dateConsommation" caption="Date consommation" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="dateFabrication" caption="Date fabrication" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>
    <dxi-column dataField="dateLivraison" caption="Date livraison" [width]="100"
                [filterOperations]="['contains']"
                [allowGrouping]="true">
    </dxi-column>

    <div *dxTemplate="let cell of 'plcTemplate'" class="text-center">
      {{cell.value}} <span *ngIf="cell.row.data.regroupe"><i class="fa-solid fa-layer-group reste"></i></span>
    </div>

    <div *dxTemplate="let cell of 'decliTemplate'">
      {{cell.value}} <span *ngIf="cell.row.data.regroupe"><i class="fa-solid fa-layer-group reste"></i></span>
    </div>

    <div *dxTemplate="let cell of 'prestaTemplate'">
      {{cell.value}} <span *ngIf="cell.row.data.regroupe"><i class="fa-solid fa-layer-group reste"></i></span>
    </div>

    <div *dxTemplate="let cell of 'poidsNetUcTemplate'">
      {{cell.value}} Kg
    </div>

    <div *dxTemplate="let cell of 'qteConditionneeTemplate'">
      {{cell.value}}
    </div>

    <div *dxTemplate="let cell of 'resteTemplate'">
      <span [className]="cell.value ? 'reste' : ''">{{cell.value || ''}} {{cell.value ? 'Kg' : ''}}</span>
    </div>

    <div *dxTemplate="let cell of 'nbPiecesTemplate'">
      {{cell.value}} {{ cell.value ? cell.row.data.uniteAConditionner : '' }}
    </div>

    <dxo-summary>
      <dxi-group-item
        column="qteConditionnee"
        summaryType="sum"
        displayFormat="Quantité conditionnée : {0}">
      </dxi-group-item>
      <dxi-group-item
        column="queueUc"
        summaryType="sum"
        displayFormat="Reste : {0}">
        <dxo-value-format type="fixedPoint" precision="3"></dxo-value-format>
      </dxi-group-item>
      <dxi-group-item
        column="nbCommandes"
        summaryType="sum"
        displayFormat="Nb.commandes : {0}">
      </dxi-group-item>
      <dxi-group-item
        column="poidsNetUc"
        summaryType="sum"
        valueFormat="##0.0000"
        displayFormat="Poids net UC : {0}">
      </dxi-group-item>
    </dxo-summary>


    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} conditionnement(s)">
    </dxo-pager>

  </dx-data-grid>

</div>

