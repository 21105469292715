// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]5.6-rc-9698(94170934a0)-C30/10/2023-18:25:44-B30/10/2023-18:28:46' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]5.6-rc-9698(94170934a0)-C30/10/2023-18:25:44-B30/10/2023-18:28:46",
  branch: "master",
  latestTag: "5.6-rc",
  revCount: "9698",
  shortHash: "94170934a0",
  longHash: "94170934a0a5b3bf8bcbdcc3fa07ff02f2a02095",
  dateCommit: "30/10/2023-18:25:44",
  dateBuild: "30/10/2023-18:28:46",
  buildType: "Ansible",
  } ;
